import { Flex, FormControl, Icon, Link, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, createChargebee } from 'apis/connections';
import { getOrgDetails, ORG_DETAILS_STATE_KEY } from 'apis/organizations-apis';
import Button from 'component-library/components/ButtonTmp/button';
import FormLabel from 'component-library/components/FormLabel';
import { KInput, KSmartInput } from 'component-library/components/Input';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { InfoIconFilled } from 'components/icons';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import useTracking from 'hooks/useTracking';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { extractChargebeeSiteId } from 'utils/utils';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { ChargebeeIcon } from './icons/chargebee';

interface FormData {
  url: string;
  api_key: string;
}

export default function ChargebeeTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId, isTest } = useOrg();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { track } = useTracking();
  const { handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const [chargebeeData, setChargebeeData] = useState<FormData>({
    url: '',
    api_key: '',
  });
  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);
  const [, setSearchParams] = useQueryParam('liveSource');
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: [ORG_DETAILS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getOrgDetails(orgId);
      return data;
    },
  });
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;

  const handleOpenChargebeeModal = () => {
    setIsBusinessAddressPromptOpen(false);
    onOpen();
  };

  const chargebeeMutation = useMutation({
    mutationFn: () => {
      const transformedData = {
        url: chargebeeData.url.trim(),
        api_key: chargebeeData.api_key.trim(),
      };

      return createChargebee(orgId, transformedData.url, transformedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      onClose();
      handleSuccessNotification('Chargbee Connected Successfully.');
      navigate('/source');
    },
  });

  const handleChargebeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'url') {
      const siteId = extractChargebeeSiteId(value);
      setChargebeeData(prev => ({ ...prev, url: siteId }));
    } else {
      setChargebeeData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmitChargebee = async () => {
    chargebeeMutation.mutate();
    track('connects integration', { integration: 'chargebee' });
  };

  const handlePatternMatched = () => {
    setChargebeeData({ api_key: '', url: '' });
    onClose();
    setSearchParams({ liveSource: 'chargebee' });
  };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title="Chargebee"
        description="SaaS for Revenue Growth Management"
        icon={<ChargebeeIcon />}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : onOpen())}
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={handleOpenChargebeeModal}
      />
      <ModalPopup
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        size={'lg'}
        isOpen={isOpen}
        onClose={onClose}
        header="Connect Chargebee"
        footer={
          <Flex gap={2}>
            <Button variant={'outline'} color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={chargebeeMutation.isPending}
              variant="solid"
              color="primary"
              width={'90px'}
              onClick={handleSubmitChargebee}
              isDisabled={chargebeeData.url === '' || chargebeeData.api_key === ''}
            >
              Save
            </Button>
          </Flex>
        }
      >
        <FormControl>
          <FormLabel color={'#262B47'}>Chargebee Site ID</FormLabel>
          <KInput name="url" value={chargebeeData.url} onChange={handleChargebeeChange} />
          <FormLabel color={'#262B47'}>
            <Flex direction={'row'} gap={2} align="center" mt={2}>
              Chargebee API key
              <Tooltip
                shouldWrapChildren
                closeDelay={1000}
                closeOnPointerDown
                pointerEvents="all"
                backgroundColor={'#262B47'}
                label={
                  <Flex direction="column" w="100%" justifyContent={'center'}>
                    <Text color="fffff">
                      Please use the read-only API key. You can find the instructions to get the API key{' '}
                      <Link
                        target="_blank"
                        href="https://kintsugi.readme.io/docs/connect-chargebee-to-kintsugi-account"
                        rel="noreferrer"
                      >
                        here
                      </Link>
                      .{' '}
                    </Text>
                  </Flex>
                }
              >
                <Icon mt={4} fontSize={'20px'} as={InfoIconFilled} />
              </Tooltip>
            </Flex>
          </FormLabel>
          <KSmartInput
            name="api_key"
            value={chargebeeData.api_key}
            onChange={handleChargebeeChange}
            shouldMatchPattern={isTest}
            pattern={'^live_\\w*$'}
            onPatternMatched={handlePatternMatched}
          />
        </FormControl>
      </ModalPopup>
    </>
  );
}
