import { Flex, IconButton, Select, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { exportFilingsSummary, FILINGS_STATE_KEY, getFilings } from 'apis/filing-apis';
import Button from 'component-library/components/ButtonTmp/button';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { ExcelIcon } from 'components/icons';
import { CountryOptions } from 'constants/app-constants';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { CountryCodeEnum } from 'types/shared-types';

export const DownloadFiling = () => {
  const { orgId } = useOrg();

  const { handleSuccessNotification } = useHandleNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [country, setCountry] = useState(CountryCodeEnum.US);

  const { data } = useQuery({
    queryKey: [FILINGS_STATE_KEY, orgId],
    queryFn: async () => {
      const res = await getFilings({ orgId });
      return res?.data;
    },
  });

  const downloadFile = (url: string, filename: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const mutation = useMutation({
    mutationFn: () => {
      return exportFilingsSummary(orgId, country);
    },
    onSuccess: response => {
      if (import.meta.env.VITE_ENVIRONMENT === 'local') {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const contentDisposition = response.headers['content-disposition'];
        const filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
        downloadFile(url, filename);
        handleSuccessNotification('Filing Report Downloaded');
      } else {
        handleSuccessNotification('Filing Report Export Started. Check your email for a download link.');
      }
      onCloseModal();
    },
  });

  const onCloseModal = () => {
    onClose();
    setCountry(CountryCodeEnum.US);
  };

  const filings = data?.items || [];
  const isDataEmpty = !filings.length;

  const handleDownloadClick = () => {
    mutation.mutate();
  };

  return (
    <>
      <Tooltip shouldWrapChildren={true} label={!isDataEmpty ? 'Export Filings' : 'No data to export'}>
        <IconButton
          isDisabled={isDataEmpty}
          isLoading={mutation.isPending}
          aria-label={'Export Filings'}
          rounded={'sm'}
          variant={'ghost'}
          pb={2}
          onClick={() => {
            onOpen();
          }}
        >
          <ExcelIcon />
        </IconButton>
      </Tooltip>
      <ModalPopup
        closeOnOverlayClick={false}
        size={'lg'}
        isOpen={isOpen}
        onClose={onCloseModal}
        header="Send Report"
        footer={
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              isLoading={mutation.isPending}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={handleDownloadClick}
            >
              Submit
            </Button>
          </Flex>
        }
      >
        <Flex direction="column" gap={3}>
          <Text>
            The report will be sent to your email address and will arrive in the next 1-2 minutes depending on how much
            data is in your organization.
          </Text>
          <Text fontWeight={500}>Country</Text>
          <Select
            isDisabled={true}
            placeholder="Select Country"
            value={country || CountryCodeEnum.US}
            onChange={e => setCountry(e.target.value as CountryCodeEnum)}
          >
            {CountryOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Flex>
      </ModalPopup>
    </>
  );
};
