import Intercom, { show } from '@intercom/messenger-js-sdk';
import { useAuthInfo } from '@propelauth/react';
import Button from 'component-library/components/ButtonTmp/button';
import CryptoJS from 'crypto-js';
import { useDebounce } from 'hooks/useDebounce';
import { useOrg } from 'hooks/useOrg';
import { useCallback, useState } from 'react';
import { IoMdHelp } from 'react-icons/io';

export default function IntercomWidget({ support }: { support?: boolean }) {
  const { user } = useAuthInfo();
  const { orgId } = useOrg();
  const [isIntercomLoading, setIsIntercomLoading] = useState(false);

  const handleClick = useCallback(async () => {
    if (!user || !orgId) {
      return;
    }

    setIsIntercomLoading(true);
    const hash = CryptoJS.HmacSHA256(user.userId, import.meta.env.VITE_INTERCOM_SECRET_KEY).toString(CryptoJS.enc.Hex);

    try {
      await new Promise(resolve => {
        Intercom({
          custom_launcher_selector: support ? '#open_web_chat_support' : '#open_web_chat',
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          user_id: user.userId,
          name: user.username,
          email: user.email,
          created_at: user.createdAt,
          Organization: orgId,
          user_hash: hash,
        });
        const timeoutId = setTimeout(resolve, 500);
        return () => clearTimeout(timeoutId);
      });
      show();
    } catch (error) {
      console.error('Failed to initialize Intercom:', error);
    } finally {
      setIsIntercomLoading(false);
    }
  }, [user, orgId, support]);

  const debouncedHandleClick = useDebounce(handleClick);

  if (support) {
    return (
      <Button
        id="open_web_chat_support"
        isLoading={isIntercomLoading}
        onClick={debouncedHandleClick}
        px={4}
        py={4}
        h={'48px'}
        w={'200px'}
        fontSize={'18px'}
        fontWeight={500}
      >
        Contact Support
      </Button>
    );
  }

  return (
    <Button
      id="open_web_chat"
      isLoading={isIntercomLoading}
      onClick={debouncedHandleClick}
      boxSize={'50px'}
      borderRadius={'full'}
      bgColor="#ffb000"
      _hover={{ bg: '#ffb000' }}
    >
      <IoMdHelp size={'20px'} />
    </Button>
  );
}
