import { FormatNumberOptions, Maybe, NumberStyleEnum } from 'types/utils';

/**
 * Formats a number or string into a formatted string representation.
 *
 * @param input - The number or string to be formatted.
 * @param options - The formatting options.
 * @returns The formatted string representation of the input.
 */
export const formatNumber = (
  input: Maybe<number | string>,
  {
    style = NumberStyleEnum.DECIMAL,
    zeroStr,
    currency,
    currencySign,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  }: FormatNumberOptions = {}
): string => {
  let value;
  if (input || input === 0) {
    value = Number(input);
  } else {
    return '';
  }
  if (zeroStr && value === 0) {
    return zeroStr;
  }

  if (maximumFractionDigits === 'max' || maximumFractionDigits > 16) {
    maximumFractionDigits = 16; // max supported by JS is documented as 20 but in practice seems to be 16
  }

  if (minimumFractionDigits > maximumFractionDigits) {
    minimumFractionDigits = maximumFractionDigits;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    currency,
    currencySign,
    signDisplay: value === 0 ? 'never' : 'auto',
  });
  const formatted = formatter.format(value);
  return formatted;
};

/**
 * Formats a number or string as a currency value.
 *
 * @param input - The number or string to format as currency.
 * @returns The formatted currency value.
 */
export const formatCurrency = (input: Maybe<number | string>) => {
  return formatNumber(input, {
    style: NumberStyleEnum.CURRENCY,
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Formats order_by fields into field's sort order.
 *
 * @param fields - The array of fields fetched from redux.
 * @param fieldName - The field name that needs sort order.
 * @returns The formatted asc or desc order of field.
 */

export const getFieldSortOrder = (fields: string[], fieldName: string) => {
  const field = fields.find(field => field.includes(fieldName));
  if (field) {
    return field.startsWith('-') ? 'desc' : 'asc';
  }
  return 'asc';
};

/**
 * Extracts the Chargebee site ID from a URL or returns the direct site ID.
 *
 * @param url - Either a full Chargebee URL (e.g., 'mysite.chargebee.com') or a direct site ID
 * @returns The extracted Chargebee site ID
 * @example
 * // Returns 'mysite'
 * extractChargebeeSiteId('mysite.chargebee.com')
 * // Returns 'mysite'
 * extractChargebeeSiteId('https://mysite.chargebee.com')
 * // Returns 'mysite'
 * extractChargebeeSiteId('mysite')
 */
export const extractChargebeeSiteId = (url: string): string => {
  if (!url) {
    return '';
  }

  const cleanUrl = url.trim();

  if (cleanUrl.toLowerCase().includes('chargebee.com')) {
    const withoutProtocol = cleanUrl.split('://').pop() || '';

    const siteId = withoutProtocol.split('.')[0];
    return siteId.toLowerCase();
  }
  return cleanUrl.toLowerCase();
};
