import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  SimpleGrid,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getNexus, NEXUS_STATE_KEY } from 'apis/dashboard-apis';
import { getNexusById, registerNexus } from 'apis/nexus';
import {
  getAllEmailGroups,
  getAutoFileAutoRegister,
  ORG_DETAILS_STATE_KEY,
  putAutoFileAutoRegister,
} from 'apis/organizations-apis';
import { REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { usePaywall } from 'app/acl/paywall';
import Button from 'component-library/components/ButtonTmp/button';
import FormLabel from 'component-library/components/FormLabel';
import MenuDropDown from 'component-library/components/Menu/MenuDropDown';
import MenuItem from 'component-library/components/Menu/MenuItem';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import KCustomSelect from 'components/KCustomSelect';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { AutoFileRegisterToggle } from 'pages/Configuration/components/org-settings/auto-file-register-toggle';
import { useEffect, useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import {
  AutoFileAutoRegisterUpdateRequest,
  NexusInstance,
  NexusStatus,
  RegistrationInstance,
  RegistrationStatus,
} from 'types/shared-types';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  country_code: Yup.string().required('Country is required'),
  state_code: Yup.string().required('State is required'),
  registration_email: Yup.string().email('Invalid email address').required('Email is required'),
  is_approaching: Yup.boolean(),
});

const RequestRegistrationFormModal = () => {
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [edit, setEdit] = useState(false);

  const isOpen = searchParams.get('requestRegistration') === 'true';
  const nexusId = searchParams.get('nexusId');
  const closeModal = () =>
    setSearchParams(params => {
      params.delete('requestRegistration');
      params.delete('nexusId');
      return params;
    });

  const { isLoading, data, isError } = useQuery({
    queryKey: [NEXUS_STATE_KEY, nexusId],
    queryFn: async () => {
      const { data } = await getNexusById(nexusId!, orgId);
      if (data) {
        formik.setFieldValue('state_code', data.state_code);
      }
      return data;
    },
    enabled: !!nexusId,
  });

  const { data: virtualMail, isLoading: isVirtualMailLoading } = useQuery({
    queryKey: ['VIRTUAL-EMAIL', orgId],
    queryFn: async () => {
      const res = await getAllEmailGroups(orgId);
      return res?.data;
    },
    enabled: !isTest,
  });

  const { isLoading: isOrgDetailsLoading, data: orgDetails } = useQuery({
    queryKey: [ORG_DETAILS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getAutoFileAutoRegister(orgId);
      return data || {};
    },
    enabled: !isTest,
  });

  const autoRegisterMutation = useMutation({
    mutationFn: async (payload: AutoFileAutoRegisterUpdateRequest) => {
      return putAutoFileAutoRegister(orgId, payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [ORG_DETAILS_STATE_KEY] });
    },
  });

  const {
    data: { jurisdictions, disabledItems, nexusByStateCode } = {
      jurisdictions: [],
      disabledItems: [],
      nexusByStateCode: {},
    },
    isLoading: jurisdictionsLoading,
  } = useQuery<
    NexusInstance[],
    unknown,
    {
      jurisdictions: { label: string; value: string }[];
      disabledItems: string[];
      nexusByStateCode: Record<string, NexusInstance>;
    }
  >({
    queryKey: [NEXUS_STATE_KEY, orgId],
    queryFn: async () => {
      const data = await getNexus(orgId);
      return data;
    },
    select: data => {
      return data.reduce<{
        jurisdictions: { label: string; value: string }[];
        disabledItems: string[];
        nexusByStateCode: Record<string, NexusInstance>;
      }>(
        (acc, curr) => {
          const { nexus_met, status, state_code, state_name } = curr;
          const isDisabled = nexus_met || status !== NexusStatus.APPROACHING;
          return {
            jurisdictions: [...acc.jurisdictions, { label: state_name, value: state_code }],
            disabledItems: isDisabled ? [...acc.disabledItems, state_code] : acc.disabledItems,
            nexusByStateCode: { ...acc.nexusByStateCode, [state_code]: curr },
          };
        },
        { jurisdictions: [], disabledItems: [], nexusByStateCode: {} }
      );
    },
    refetchOnWindowFocus: false,
  });

  const autoRegisterformik = useFormik({
    initialValues: {
      auto_register: orgDetails?.auto_register || false,
      auto_file: orgDetails?.auto_file || false,
    },
    onSubmit: async values => {
      await autoRegisterMutation.mutateAsync(values);
    },
    enableReinitialize: true,
  });

  const formik = useFormik({
    initialValues: {
      country_code: 'US',
      state_code: data?.state_code as string,
      registration_email: virtualMail ? (virtualMail[0] as string) : (data?.registration_email as string),
      status: RegistrationStatus.REGISTERED,
      is_approaching: false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validate: values => {
      if (!nexusId && !values.is_approaching) {
        return {
          is_approaching: 'Make sure to check this box if you are not sure if you have met nexus here.',
        };
      }
    },
    onSubmit: values => {
      mutation.mutate({
        id: nexusId ? nexusId : nexusByStateCode[values.state_code].id!,
        payload: values,
      });
    },
  });

  const isCountryCodeInvalid = !!(formik.touched.country_code && formik.errors.country_code);
  const isStateCodeInvalid = !!(formik.touched.state_code && formik.errors.state_code);
  const isRegistrationEmailInvalid = !!(formik.touched.registration_email && formik.errors.registration_email);
  const isApproachingInvalid = !!(formik.touched.is_approaching && formik.errors.is_approaching);

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);

  const onClose = () => {
    setSearchParams({});
    closeModal();
    setEdit(false);
  };

  useEffect(() => {
    if (!virtualMail?.[0] && !data?.registration_email) {
      setEdit(true);
    }
  }, [virtualMail, data]);

  const mutation = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: Partial<RegistrationInstance> }) => {
      return registerNexus(id, orgId, payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      queryClient.invalidateQueries({ queryKey: [NEXUS_STATE_KEY] });
      if (autoRegisterformik.isValid && autoRegisterformik.values.auto_register) {
        await autoRegisterformik.submitForm();
      }
      onClose();
      handleSuccessNotification(
        nexusId ? 'Registration updated successfully.' : 'Registration request successfully submitted'
      );
    },
  });

  return (
    isOpen && (
      <ModalPopup
        closeOnOverlayClick={false}
        size={'lg'}
        isOpen={isOpen}
        onClose={onClose}
        header={'Request Registration'}
        footer={
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={mutation.isPending}
              isDisabled={formik.isSubmitting || !formik.isValid}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => {
                if (data?.is_manual) {
                  formik.setValues({ ...formik.values, status: RegistrationStatus.REGISTERED });
                } else {
                  formik.setValues({ ...formik.values, status: RegistrationStatus.PROCESSING });
                }
                if (formik.isValid) {
                  formik.handleSubmit();
                } else {
                  formik.setTouched({
                    registration_email: true,
                  });
                }
              }}
            >
              Save
            </Button>
          </Flex>
        }
      >
        {isLoading || isVirtualMailLoading || isOrgDetailsLoading ? (
          <VStack spacing={4}>
            {Array.from({ length: 3 }, (_, index) => (
              <Skeleton key={index} height="30px" width="full" />
            ))}
          </VStack>
        ) : (
          <SimpleGrid rowGap={4}>
            <SimpleGrid columns={2} gap={4}>
              <FormControl isInvalid={isCountryCodeInvalid}>
                <FormLabel htmlFor="country_code">Country</FormLabel>
                <Input
                  disabled
                  isReadOnly
                  id="country_code"
                  type="text"
                  name="country_code"
                  value={formik.values.country_code}
                />
                {isCountryCodeInvalid && <FormErrorMessage>{formik.errors.country_code}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={isStateCodeInvalid} isRequired>
                <FormLabel htmlFor="state_code">State</FormLabel>
                <KCustomSelect
                  id="state_code"
                  name="state_code"
                  itemTitle="label"
                  itemValue="value"
                  isDisabled={jurisdictionsLoading}
                  value={formik.values.state_code}
                  onChange={value => {
                    formik.setFieldValue('state_code', value);
                  }}
                  items={jurisdictions}
                  disabledItems={disabledItems}
                  renderItemContent={({ title, isDisabled, color }) => (
                    <Tooltip
                      isDisabled={!isDisabled}
                      label="You have either met nexus here or are already registered."
                      shouldWrapChildren
                      placement="bottom-start"
                    >
                      <Text color={color}>{title}</Text>
                    </Tooltip>
                  )}
                />
                {isStateCodeInvalid && <FormErrorMessage>{formik.errors.state_code}</FormErrorMessage>}
              </FormControl>
            </SimpleGrid>
            <FormControl isInvalid={isRegistrationEmailInvalid} isRequired>
              <FormLabel htmlFor="registration_email">Registration Email</FormLabel>
              {edit ? (
                <Input
                  id="registration_email"
                  type="email"
                  name="registration_email"
                  value={formik.values.registration_email}
                  onChange={formik.handleChange}
                />
              ) : (
                <HStack justifyContent={'space-between'}>
                  <Text>{formik.values.registration_email}</Text>
                  <MenuDropDown
                    trigger={<MdMoreVert size={'20px'} />}
                    itemList={[
                      <MenuItem key="edit" onClick={() => setEdit(true)}>
                        Edit
                      </MenuItem>,
                    ]}
                  />
                </HStack>
              )}

              {isRegistrationEmailInvalid && <FormErrorMessage>{formik.errors.registration_email}</FormErrorMessage>}
            </FormControl>
            {isPaidUser && !orgDetails?.auto_register && (
              <AutoFileRegisterToggle
                label="Auto Register"
                fieldName="auto_register"
                formik={autoRegisterformik}
                p={2}
                my={2}
                fontSize={'sm'}
                tooltip={
                  <>
                    <Text color={'white'} mb={2}>
                      Automatically approve registrations as soon as the nexus is met. You can toggle this OFF anytime
                      from Settings.
                    </Text>
                    <Text color={'white'}>
                      Please ensure you complete your tasks, such as validating addresses and approving products so that
                      registrations can be auto-approved.
                    </Text>
                  </>
                }
              />
            )}
            {!nexusId && (
              <FormControl isInvalid={isApproachingInvalid}>
                <Checkbox
                  isDisabled={!isPaidUser}
                  name="is_approaching"
                  isChecked={formik.values.is_approaching}
                  onChange={formik.handleChange}
                >
                  {
                    "I understand that registration in this jurisdiction may not be necessary since I haven't met nexus here."
                  }
                </Checkbox>
                {isApproachingInvalid && <FormErrorMessage>{formik.errors.is_approaching}</FormErrorMessage>}
              </FormControl>
            )}
          </SimpleGrid>
        )}
      </ModalPopup>
    )
  );
};

export const RequestRegistrationForm = () => {
  const { isPaidUser, openPaymentCheckout } = usePaywall();
  const { isTest } = useOrg();
  const [requestRegistration, setSearchParams] = useQueryParam('requestRegistration', '');

  useEffect(() => {
    if (requestRegistration && !isPaidUser && !isTest) {
      openPaymentCheckout({
        onClose: () => {
          setSearchParams({});
        },
      });
      return;
    }
  }, [requestRegistration]);

  return requestRegistration && (isTest || isPaidUser) ? <RequestRegistrationFormModal /> : null;
};
