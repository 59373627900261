import { Flex } from '@chakra-ui/react';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useTableFilters } from 'hooks/useTableFilters';
import { CreateFiling } from 'pages/Filing/components/create-filing';
import { DownloadFiling } from 'pages/Filing/components/download-filing';
import { FilingFilterButton } from 'pages/Filing/components/FilingFilter/FilingPopover';
import FilingHistoryButton from 'pages/Filing/components/history-filings/filing-history-button';
import { FilingStatus } from 'types/shared-types';
import { getFormattedDate } from 'utils/dates';

export const FilingTrackingToolbar = () => {
  const currentMonthStart = getFormattedDate(startOfMonth(new Date()), 'yyyy-MM-dd');
  const currentMonthEnd = getFormattedDate(endOfMonth(new Date()), 'yyyy-MM-dd');

  const tableFilters = useTableFilters({
    status__in: [FilingStatus.FILING, FilingStatus.UNFILED, FilingStatus.FILED],
    date_filed__gte: currentMonthStart,
    date_filed__lte: currentMonthEnd,
  });

  return (
    <Flex gap={3} alignItems={'top'}>
      <DownloadFiling />
      <FilingFilterButton tableFilters={tableFilters} />
      <FilingHistoryButton />
      <CreateFiling />
    </Flex>
  );
};
